<template>
    <login-layout>

        <template #heading>
            <div class="pos-r">
                <div>
                    <h2 class="text-white">Login to <br> Bank e Auctions India.com</h2>
                    <p class="font-inter-extra-light text-white fs-lg-7 m-0">Bank Admin Portal</p>
                </div>
            </div>
        </template>

        <template #default>
            <div class="mt-4">

                <div class="text-center mb-5">
                    <h5 class="fs-lg-6 mb-1 font-inter-semi-bold">Set a New Password</h5>
                    <p class="font-inter-medium fs--1 ml-2 text-5 text-center">
                        Please enter your new password
                    </p>
                </div>

                <div class="mt-3 fl-x" v-if="showSuccess">
                    <span><i class="fa fa-check-circle fa-3x text-success"></i></span>
                    <span class="ml-lg-5 fs-lg-2">
                        Your new password has been updated. <br>
                        Please <router-link class="text-decoration-none text-secondary"
                                            to="/login/">Login</router-link>
                        to continue.
                    </span>
                </div>

                <b-form v-else :key="formKey" ref="contactForm" :save-url="addUrl" @success="formSuccess"
                        @failure="formError" v-slot="{model, loading}"
                        :save-params="{username: $route.params.username}">

                    <validated-input label="Enter new password" placeholder=""
                                     :type="testType" name="New Password"
                                     v-model="model.new_password" class="text-secondary c-input-1 mb-1 c-form-input"
                                     :disabled="loading" :rules="{required : true}"/>

                    <validated-input label="Confirm new password" placeholder=""
                                     :type="testType" name="Confirm Password"
                                     v-model="model.conform_password" class="text-secondary c-input-1 mb-1 c-form-input"
                                     :disabled="loading" :rules="{required : true}"/>

                    <validated-checkbox label="Show password" :disabled="loading"
                                        v-model="showPasswordType"></validated-checkbox>

                    <span class="text-danger" v-if="errorMessage" v-html="errorMessage"></span>

                    <div class="text-center mt-5">
                        <btn class="px-5 font-inter-medium" block text="Submit" loading-text="Validating..." size=""
                             :disabled="loading" :loading="loading"/>
                    </div>

                </b-form>

            </div>

        </template>

    </login-layout>
</template>

<script>
import LoginLayout from '@/views/auth/LoginPageLayout';
import urls from '@/data/urls';

export default {
    name : 'SetNewPassword',

    components : { LoginLayout },

    watch : {
        showPasswordType : {
            handler () {
                this.showPassword();
            }
        }
    },

    data () {
        return {
            showSuccess      : false,
            showPasswordType : false,
            testType         : 'password',
            formKey          : 1,

            errorMessage : '',
            addUrl       : urls.auth.resetPassword
        };
    },

    methods : {
        showPassword () {
            if (this.showPasswordType === false) {
                this.testType = 'password';
            } else {
                this.testType = 'text';
            }
        },

        formSuccess () {
            this.showSuccess = true;
            this.formKey += 1;
        },

        formError (response) {
            const data = response.data;
            if (data.errors.Username) {
                this.$notify(data.errors.Username, 'Failed', {
                    type : 'danger'
                });
            } else {
                this.$notify('There were some errors while communicating with the server. Please refresh the page or try again later.', 'Failed', {
                    type : 'danger'
                });
            }
        }
    }
};
</script>

<style scoped>

</style>
